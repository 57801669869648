import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/Home'),
    hidden: true,
    meta: {
      title: '首页',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/Login'),
    meta: {
      title: '登录',
    },
  },
  {
    path: '/add',
    name: 'AddRecord',
    component: () => import('@/views/home/Add_record'),
    meta: {
      title: '填写申报',
    },
  },
  {
    path: '/history',
    name: 'History',
    component: () => import('@/views/home/Record_list'),
    meta: {
      title: '我的草稿',
    },
  },
  {
    path: '/detail',
    name: 'Detail',
    component: () => import('@/views/home/Detail'),
    meta: {
      title: '申报详情',
    },
  }
]

const router = createRouter({
  history: createWebHistory('/'),
  routes
})

export default router
