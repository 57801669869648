<template>
  <div class="home">
    <!-- <van-pull-refresh v-model="loading" @refresh="onRefresh"> -->
      <div class="main-container">
        <div class="content-list">
          <van-nav-bar
            title="请选择"
            @click-left="onClickLeft"
            @click-right="onClickRight"
            right-text="退出"
          />
          <!-- <van-nav-bar title="申报记录" left-text="草稿箱" right-text="退出" @click-left="onClickLeft"
                        @click-right="onClickRight" /> -->
          <!-- <van-field label-width="190" required right-icon="arrow" readonly v-model="form.companyName" -->
          <!-- name="当前企业" label="当前企业" placeholder="请选择" @click="show = true" /> -->
          <!-- <div v-if="dataList && dataList.length > 0" class="list-wrap">
                        <div v-for="item in dataList" :key="item.declareId" class="item-init" @click="toDetail(item)">
                            <div class="name-wrap">
                                <div class="name">{{ item.farmName }}</div>
                                <div class="time">{{ item.createTime }}</div>
                            </div>
                            <div class="type">
                                <span v-if="item.status == 'pass_review'">已完成</span>
                                <span v-else>待复核</span>
                            </div>
                        </div>
                    </div>
                    <van-empty v-else description="暂无申报记录" /> -->
          <van-collapse v-model="activeNames" bind:change="onChange">
            <van-collapse-item
              v-for="(item, index) in dataList"
              :title="item.year + '年度'"
              :name="index"
            >
              <div
                class="list_box"
                :style="{ 'border-top': ins !== 0 ? '1px solid #F5F6F7' : '' }"
                @click="goDetail(list)"
                v-for="(list, ins) in item.yearCompanyVoList"
              >
                <span>{{ list.companyName }}</span>
                <span :class="'status_' + list.reviewStatus">{{
                  list.reviewStatus=='wait_review'?'待复核':list.reviewStatus=='pass_review'?'已完成':list.reviewStatus=='refund'?'退回':'未申报'
                }}</span>
              </div>
              <div class="list_box" v-if="item.yearCompanyVoList.length==0">该年度未申报</div>
            </van-collapse-item>
          </van-collapse>
        </div>
      </div>
    <!-- </van-pull-refresh> -->
    <!-- <div class="footer-btn">
            <van-button style="width: 200px;" size="small" type="primary" @click="toAddPage">新增</van-button>
        </div> -->
    <!-- <van-action-sheet v-model:show="show" :actions="actions" @select="onSelect" /> -->
  </div>
</template>

<script>
import { removeToken } from "@/utils/auth";
import { declareList, companyList } from "@/api/api";

export default {
  data() {
    return {
      count: 0,
      show: true,
      loading: false,
      userPhone: "", //用户手机号
      activeNames: [0],
      form: JSON.parse(localStorage.getItem("form")),
      actions: [],
      dataList: [
        // {
        //   year: "2024年度",
        //   yearCompanyVoList: [
        //     {
        //       companyName: "贵州富之源科技集团",
        //       statusLabel: "已申报",
        //       reviewStatus: "pass_review",
        //     },
        //     {
        //       companyName: "贵州农业发展有限公司",
        //       statusLabel: "未申报",
        //       reviewStatus: "",
        //     },
        //   ],
        // },
        // {
        //   year: "2023年度",
        //   yearCompanyVoList: [
        //     {
        //       companyName: "贵州猛猪科技有限公司",
        //       statusLabel: "已完成",
        //       reviewStatus: 3,
        //     },
        //   ],
        // },
      ],
    };
  },
  created() {
    if (!localStorage.getItem("user")) {
      this.$router.push("/login");
    } else {
      this.userPhone = JSON.parse(localStorage.getItem("user")).userName;
    }
    this.getDeclareListFn();
  },
  methods: {
    goDetail(data) {
      this.$router.push(
        `/add?declareId=${data.declareId}&data=${JSON.stringify(
          data
        )}`
      );
    },
    onChange(event) {
      this.activeNames = event.detail;
    },
    onClickLeft() {
      this.$router.push("/history");
    },
    onSelect(e) {
      this.show = false;
      this.form.companyName = e.companyName;
      this.form.companyId = e.companyId;
      this.dataList = [];
      this.getDeclareListFn();
    },
    onRefresh() {
      // this.loading = true;
      // setTimeout(() => {
      //   this.$toast("刷新成功");
      //   this.loading = false;
      //   this.dataList = [];
      //   this.queryForm.pageNum = 1;
      //   this.getDeclareListFn();
      // }, 300);
    },

    /**
     * 获取申报列表数据
     */
    getDeclareListFn() {
      companyList(this.userPhone).then((res) => {
        this.dataList = res.data;
      });
      //   companyList("13827020800").then((res) => {
      //     console.log(res, "eeeeee");
      //     this.dataList = res.data;
      //   });
    },

    onClickRight() {
      removeToken();
      localStorage.removeItem("user");
      this.$router.replace("/login");
    },
    toAddPage() {
      this.show = false;
      this.$router.push(
        "/add?editType=1&companyId=" +
          this.form.companyId +
          "&companyName=" +
          this.form.companyName
      );
    },
    toDetail(item) {
      this.$router.push("/detail?declareId=" + item.declareId);
    },
  },
};
</script>
<style lang="scss" scoped>
.main-container {
  min-height: 100vh;
}
.list_box {
  padding: 10px;
  /* border-top: 1px solid red; */
  display: flex;
  justify-content: space-between;
  span:last-child {
    padding: 0 8px;
    width: 70px;
    text-align: center;
    display: inline-block;
    border-radius: 3px;
    border: 1px solid #165dff;
    color: #165dff;
  }
  span.status_wait_review {
    border: 1px solid #f6b91e;
    color: #f6b91e;
  }
  span.status_pass_review {
    border: 1px solid #0dbe5e;
    color: #0dbe5e;
  }
  span.status_refund{
    border: 1px solid #f44302;
    color: #f44302;
  }
}
.content-list {
  height: calc(100% - 51px);

  .list-wrap {
    box-sizing: border-box;
    padding: 14px;
    height: calc(100% - 51px);
    overflow: auto;

    .item-init {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #fff;
      height: 50px;
      padding: 0 10px;
      border-radius: 10px;
      margin-bottom: 14px;

      .name-wrap {
        .name {
          font-weight: bold;
          font-size: 14px;
        }

        .time {
          font-size: 12px;
          color: #999;
        }
      }

      .type {
        font-size: 12px;
        border: 1px solid #409eff;
        color: #409eff;
        border-radius: 6px;
        width: 70px;
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.footer-btn {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}
</style>
