import request from '@/utils/request'

// 登录方法
export function login(data) {
    return request({
        url: '/smsLogin',
        method: 'post',
        data: data
    })
}

// 获取用户登录个人信息
export function getUserInfo() {
    return request({
        url: '/getInfo',
        method: 'get'
    })
}

// 发送验证码
export function sendCode(data) {
    return request({
        url: '/base/sms/sendCode',
        headers: {
            "Content-Type": 'application/x-www-form-urlencoded'
        },
        method: 'post',
        data: data
    })
}