<template>
  <div class="login-container">
      <div class="top-bg">
        <div class="title">能繁母猪大数据平台</div>
        <img class="bg" src="../../assets/login_bg.png" alt="" />
        <img src="../../assets/text_tips.png" alt="" class="text" />
      </div>
      <div class="form">
        <van-form @submit="onSubmit">
          <van-cell-group>
            <van-field
              autosize
              v-model="form.phone"
              type="number"
              clearable
              left-icon="manager"
              placeholder="请输入手机号"
              :rules="[{ required: true, message: '请填写手机号' }]"
            />
            <van-field
              autosize
              v-model="form.code"
              clearable
              left-icon="envelop-o"
              placeholder="短信验证码"
              :rules="[{ required: true, message: '请填写验证码' }]"
            >
              <template #button>
                <van-button
                  v-if="show"
                  @click="sendCodeFn"
                  style="width: 100px"
                  size="small"
                  type="primary"
                  >发送验证码</van-button
                >
                <van-button
                  v-else
                  disabled
                  style="width: 100px"
                  size="small"
                  color="#ccc"
                  >{{ count }}</van-button
                >
              </template>
            </van-field>
          </van-cell-group>
          <van-button
            style="margin-top: 10vh"
            block
            type="primary"
            native-type="submit"
            >登录</van-button
          >
        </van-form>
      </div>
      <van-overlay :show="selectShow">
        <div class="wrapper">
          <div class="block">
            <p
              v-for="item in actions"
              :key="item.companyId"
              @click="selectFun(item)"
            >
              {{ item.name }}
            </p>
          </div>
        </div>
      </van-overlay>
  </div>
</template>

<script>
import { login, sendCode, getUserInfo } from "@/api/login";
// import { declareList, companyList } from "@/api/api";
import { setToken } from "@/utils/auth";
export default {
  data() {
    return {
      form: {
        phone: "",
        code: "",
      },
      TIME_COUNT: 60,
      count: 0,
      timer: null,
      selectShow: false,
      show: true,
      actions: [],
      queryForm: {
        pageSize: 10,
        pageNum: 1,
        declareUserPhone: "",
        draftFlag: "N", // 草稿标识：Y-草稿，N-非草稿
      },
    };
  },
  created() {},
  methods: {
    async onSubmit() {
      try {
        const res = await login({
          phonenumber: this.form.phone,
          smsCode: this.form.code,
        });
        setToken(res.data.token);
        getUserInfo().then(async (response) => {
          localStorage.setItem("user", JSON.stringify(response.data.user));
          this.$router.replace("/");
          // this.$router.replace('/')
          // const res = await declareList(this.queryForm)
          // if (res.total > 0) {
          // this.$router.replace('/')
          // } else {
          // this.$router.replace('/add')
          // }
          // companyList(response.data.user.userName).then(res => {
          //     res.data.forEach(el => {
          //         el.name = el.companyName
          //     })
          //     // this.form.companyName = res.data[0].companyName
          //     // this.form.companyId = res.data[0].companyId
          //     this.actions = res.data
          //     this.selectShow = true
          // })
        });
      } catch (error) {
        this.$toast(error);
      }
    },
    async sendCodeFn() {
      if (!/^1[3-9]\d{9}$/.test(this.form.phone)) return this.$toast("请输入正确的手机号");
      try {
        const res = await sendCode({ phoneNumber: this.form.phone });
        this.$toast("验证码发送成功");
        const TIME_COUNT = this.TIME_COUNT;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.show = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      } catch (error) {
        this.$toast(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 90%;
  min-height: 120px;
  border-radius: 10px;
  background-color: #fff;
  padding: 20px;

  p {
    margin-top: 10px;
  }
}

.van-cell {
  align-items: center;
}

.login-container {
  background-color: #fff;
  height: 100vh;
}

.form {
  padding: 0 10px;
}

.top-bg {
  position: relative;

  .title {
    position: absolute;
    top: 50px;
    color: #fff;
    font-size: 24px;
    width: 100%;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bg {
    width: 100%;
  }

  .text {
    position: absolute;
    width: 100px;
    height: auto;
    left: 50%;
    margin-left: -50px;
    bottom: 50px;
  }
}
</style>
