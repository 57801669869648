<template>
  <div>
    <van-nav-bar
      :title="title"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="form-container-wrap">
      <div class="firm_name">
        <span style="font-weight: bold">{{ form.companyName }}</span>
        <span :class="'status_' + reviewStatus">{{
          reviewStatus == "pass_review"
            ? "已完成"
            : reviewStatus == "wait_review"
            ? "待复核"
            : reviewStatus == "refund"
            ? "退回"
            : "未申报"
        }}</span>
      </div>
      <van-form @submit="onSubmit">
        <div class="form-container">
          <van-field
            label-width="100"
            readonly
            required
            v-model="year"
            name="申报年度"
            label="申报年度"
            placeholder="请输入"
            :rules="[{ required: true, message: '请填写申报年度' }]"
          />
          <van-field
            label-width="100"
            readonly
            required
            v-model="form.companyName"
            name="企业名称"
            label="企业名称"
            placeholder="请输入"
            :rules="[{ required: true, message: '请填写企业名称' }]"
          />
          <van-field
            label-width="100"
            required
            v-model="form.companyUser"
            name="法定负责人"
            label="法定负责人"
            placeholder="请输入"
            :readonly="read_only"
            :rules="[{ required: true, message: '请填写法定负责人' }]"
          />
          <van-field
            label-width="100"
            required
            v-model="form.companyUserID"
            name="法人身份证号"
            label="法人身份证号"
            placeholder="请输入"
            :readonly="read_only"
            :rules="[{ required: true, message: '请填写法人身份证号' }]"
          />
          <van-field
            label-width="100"
            required
            v-model="form.companyUserPhone"
            name="法人手机号码"
            label="法人手机号码"
            placeholder="请输入"
            :readonly="read_only"
            :rules="[{ required: true, message: '请填写法人手机号码' }]"
          />
          <van-field
            label-width="100"
            required
            v-model="form.companyBank"
            name="开户行"
            label="开户行"
            placeholder="请输入"
            :readonly="read_only"
            :rules="[{ required: true, message: '请填写开户行' }]"
          />
          <van-field
            label-width="100"
            required
            type="digit"
            v-model="form.companyBankAccount"
            name="银行账户"
            label="银行账户"
            placeholder="请输入"
            :readonly="read_only"
            :rules="[{ required: true, message: '请填写银行账户' }]"
          />
          <div class="field-line">养殖信息</div>
          <van-field
            label-width="120"
            required
            v-model="form.baseName"
            name="生猪养殖基地名称"
            label="生猪养殖基地名称"
            placeholder="请输入"
            :readonly="read_only"
            :rules="[{ required: true, message: '请填写生猪养殖基地名称' }]"
          />
          <van-field
            label-width="100"
            @click="initMap"
            readonly
            required
            v-model="form.baseAddress"
            name="基地位置"
            :readonly="read_only"
            label="基地位置"
            placeholder="未获取当前位置，点击重试"
            right-icon="location"
            :rules="[
              {
                required: true,
                message: '定位失败，请检查手机、微信是否开启位置服务再试试!',
              },
            ]"
          />
          <van-field
            label-width="100"
            :readonly="read_only"
            v-model="form.husbandryCode"
            name="畜禽养殖代码"
            label="畜禽养殖代码"
            placeholder="请输入"
          />
          <van-field
            label-width="170"
            :readonly="read_only"
            required
            v-model="form.animalCode"
            name="动物防疫条件合格证编码"
            label="动物防疫条件合格证编码"
            placeholder="请输入"
            :rules="[
              { required: true, message: '请填写动物防疫条件合格证编码' },
            ]"
          />
          <van-field
            label-width="190"
            required
            right-icon="arrow"
            readonly
            v-model="form.isExpire"
            name="动物防疫条件合格证是否到期"
            label="动物防疫条件合格证是否到期"
            placeholder="请选择"
            :rules="[
              { required: true, message: '请选择动物防疫条件合格证是否到期' },
            ]"
            @click="selectShow('hgz')"
          />
          <van-field
            label-width="180"
            :readonly="read_only"
            required
            type="digit"
            v-model="form.sowFullNum"
            name="能繁母猪满负荷存栏数(头)"
            label="能繁母猪满负荷存栏数(头)"
            placeholder="请输入"
            :rules="[{ required: true, message: '请填写能繁母猪满负荷存栏数' }]"
          />
          <van-field
            label-width="170"
            :readonly="read_only"
            required
            type="digit"
            v-model="form.sowCurrentNum"
            name="能繁母猪当前存栏数(头)"
            label="能繁母猪当前存栏数(头)"
            placeholder="请输入"
            :rules="[{ required: true, message: '请填写能繁母猪当前存栏数' }]"
          />
          <van-field
            label-width="190"
            required
            right-icon="arrow"
            readonly
            v-model="form.digitizeFlag"
            name="是否使用数智化软件"
            label="是否使用数智化软件"
            placeholder="请选择"
            :rules="[{ required: true, message: '请选择是否使用数智化软件' }]"
            @click="selectShow('szh')"
          />
          <!-- <van-field label-width="170" required v-model="form.fitPigFullNum" name="肥猪满负荷存栏数(头)"
                        label="肥猪满负荷存栏数(头)" placeholder="请输入" :rules="[{ required: true, message: '请填写' }]" />
                    <van-field label-width="170" required v-model="form.fitPigCurrentNum" name="肥猪当前存栏数(头)"
                        label="肥猪当前存栏数(头)" placeholder="请输入" :rules="[{ required: true, message: '请填写' }]" />
                    <van-field label-width="170" required v-model="form.subsidyAmount" name="申请补贴用金额(万元)"
                        label="申请补贴用金额(万元)" placeholder="请输入" :rules="[{ required: true, message: '请填写' }]" /> -->
          <van-field
            class="mark-item"
            :readonly="read_only"
            v-model="form.remark"
            rows="3"
            label-align="top"
            autosize
            label="其他补充说明"
            type="textarea"
            maxlength="200"
            placeholder="请输入说明"
            show-word-limit
          />
          <div class="field-line">材料拍摄</div>
          <div
            style="background-color: #fff; padding: 10px; border-radius: 5px"
          >
            <div class="image-tips">
              <img class="tips-img" src="../../assets/sl1.jpg" alt="" />
              <span>示例图片：</span>
              <span
                >在光线充足的条件下，对关能繁母猪的每一栋都要进行拍摄，拍摄时要拍到整栋的母猪。</span
              >
            </div>
            <div class="upload-container">
              <div class="upload-image-wrap">
                <van-uploader
                  :readonly="read_only"
                  v-model="form.fileList1"
                  multiple
                  capture="camera"
                  :after-read="afterReadFirst"
                  :deletable="!read_only"
                >
                  <template #default>
                    <div class="my-upload-style">
                      <van-icon name="plus" size="30" color="#ccc" />
                      <span>拍照上传</span>
                    </div>
                  </template>
                </van-uploader>
              </div>
              <div class="upload-tips-text">猪场照片</div>
            </div>
            <div class="image-tips">
              <img class="tips-img" src="../../assets/sl2.jpg" alt="" />
              <span>示例图片：</span>
              <span>猪场全景图，需要看见猪场全貌。</span>
            </div>
            <div class="upload-container">
              <div class="upload-image-wrap">
                <van-uploader
                  :readonly="read_only"
                  v-model="form.fileList2"
                  multiple
                  :after-read="afterReadSecond"
                  :deletable="!read_only"
                >
                  <template #default>
                    <div class="my-upload-style">
                      <van-icon name="plus" size="30" color="#ccc" />
                      <span>图片上传</span>
                    </div>
                  </template>
                </van-uploader>
              </div>
              <div class="upload-tips-text">猪场全景图</div>
            </div>
          </div>
        </div>
        <div class="submit-wrap" v-if="!read_only">
          <div class="btn-left" @click="saveDraft">
            <img class="icon" src="../../assets/cg.png" alt="" />
            <span>存草稿</span>
          </div>
          <van-button block type="primary" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
    </div>
    <van-action-sheet
      v-model:show="show"
      :actions="actions"
      @select="onSelect"
    />
  </div>
</template>
<script>
import {
  upload,
  declareAdd,
  declarePut,
  declareDetail,
  noWatermark,
} from "@/api/api";
import { parseTime } from "@/utils/util";
import AMapLoader from "@amap/amap-jsapi-loader";
window._AMapSecurityConfig = {
  securityJsCode: "19930e7ff85304a4d3d8285810eb6988", //你的秘钥
};

export default {
  data() {
    return {
      read_only: false, //是否仅读
      year: "", //显示年度字段
      show: false, //选择框开关
      actions: [
        { name: "是", value: "Y" },
        { name: "否", value: "N" },
      ],
      address: "",
      title: "申报记录",
      declareId: "",
      form: {
        companyId: "", //企业id
        companyName: "", // 企业名称
        companyUser: "", // 法定负责人
        companyUserID: "", // 法人身份证号
        companyUserPhone: "", // 法人手机号码
        companyBank: "", // 开户行
        companyBankAccount: "", // 银行账户
        baseName: "", // 生猪养殖基地名称
        baseAddress: "", // 基地位置
        longitude: "", // 经度
        latitude: "", // 纬度
        husbandryCode: "", // 畜禽养殖代码
        animalCode: "", // 动物防疫条件合格证编码
        isExpire: "", // 动物防疫条件合格证是否到期
        sowFullNum: "", // 能繁母猪满负荷存栏数(头)
        sowCurrentNum: "", // 能繁母猪当前存栏数(头)
        digitizeFlag: "", //是否使用数字化软件
        // fitPigFullNum: '', // 肥猪满负荷存栏数(头)
        // fitPigCurrentNum: '', // 肥猪当前存栏数(头)
        // subsidyAmount: '', // 申请补贴用金额(万元)
        remark: "", // 其他补充说明
        yearCompanyId: "", //申报年度企业id
        fileList1: [], // 猪场照片
        fileList2: [], // 猪场全景图片
      },
    };
  },
  created() {
    let data = this.$route.query;
    let fromData = JSON.parse(data.data);
    this.year = fromData.year + "年度";
    this.declareId = "";
    this.reviewStatus = fromData.reviewStatus;
    this.read_only =
      fromData.reviewStatus === null ||
      fromData.reviewStatus === "refund" ||
      fromData.reviewStatus === "wait_declare"
        ? false
        : true;
    if (data.declareId != "null") {
      this.declareId = data.declareId;
      this.getDeclareDetail();
    } else {
      for (let key in this.form) {
        this.form[key] = fromData[key];
        this.form.fileList1 = [];
        this.form.fileList2 = [];
      }
    }
  },
  mounted() {
    this.initMap();
  },
  methods: {
    selectShow(type) {
      if (this.read_only) return;
      this.type = type;
      this.show = true;
    },
    onClickLeft() {
      this.$router.back();
    },
    onSelect(e) {
      if (this.type == "hgz") {
        this.form.isExpire = e.name;
      }
      if (this.type == "szh") {
        this.form.digitizeFlag = e.name;
      }
      this.show = false;
    },

    // 编辑获取详情回显
    async getDeclareDetail() {
      const { data } = await declareDetail(this.declareId);
      let imageList1 = [];
      let imageList2 = [];
      if (data.declareFileList && data.declareFileList.length > 0) {
        data.declareFileList.forEach((item) => {
          let obj = {
            fileName: item.fileName,
            ossId: item.ossId,
            url: item.fileUrl,
          };
          if (item.fileType == 1) {
            imageList1.push(obj);
          }
          if (item.fileType == 2) {
            imageList2.push(obj);
          }
        });
      }

      this.form = {
        companyId: data.companyId,
        yearCompanyId: data.yearCompanyId, //申报年度id
        status: data.status, //状态
        companyName: data.companyName, // 企业名称
        companyUser: data.legalPerson, // 法定负责人
        companyUserID: data.idCardNo, // 法人身份证号
        companyUserPhone: data.phone, // 法人手机号码
        companyBank: data.bank, // 开户行
        companyBankAccount: data.bankNo, // 银行账户
        baseName: data.farmName, // 生猪养殖基地名称
        baseAddress: data.farmAddress, // 基地位置
        longitude: data.farmLongitude, // 经度
        latitude: data.farmLatitude, // 纬度
        husbandryCode: data.husbandryCode, // 畜禽养殖代码
        animalCode: data.vacCode, // 动物防疫条件合格证编码
        isExpire: data.vacExpiration == "Y" ? "是" : "否", // 动物防疫条件合格证是否到期
        sowFullNum: data.sowNumber, // 能繁母猪满负荷存栏数(头)
        sowCurrentNum: data.currentSowNumber, // 能繁母猪当前存栏数(头)
        digitizeFlag: data.digitizeFlag == "Y" ? "是" : "否", // 是否使用数字化
        // yearCompanyId:data.yearCompanyId,//申报年度企业id
        // fitPigFullNum: data.fatPigNumber, // 肥猪满负荷存栏数(头)
        // fitPigCurrentNum: data.currentFatPigNumber, // 肥猪当前存栏数(头)
        // subsidyAmount: data.subsidyAmount, // 申请补贴用金额(万元)
        remark: data.remark, // 其他补充说明
        fileList1: imageList1, // 猪场照片
        fileList2: imageList2, // 猪场全景图片
      };
    },

    // 提交申报
    async onSubmit() {
      let params = this.paramsInit();
      if (params === null) {
        return;
      }
      params.draftFlag = "N";
      if (!this.declareId) {
        // 新增
        try {
          const res = await declareAdd(params);
          this.$toast("申报提交成功");
          this.$router.back();
        } catch (error) {
          this.$toast(error);
        }
      } else {
        try {
          params.declareId = this.declareId;
          const res = await declarePut(params);
          this.$toast("申报提交成功");
          this.$router.back();
        } catch (error) {
          this.$toast(error);
        }
      }
    },

    // 保存草稿
    async saveDraft() {
      let params = this.paramsInit();
      params.draftFlag = "Y";
      if (!this.declareId) {
        // 新增
        try {
          const res = await declareAdd(params);
          this.$toast("保存草稿成功");
          this.$router.back();
        } catch (error) {
          this.$toast(error);
        }
      } else {
        // 编辑
        try {
          params.declareId = this.declareId;
          const res = await declarePut(params);
          this.$toast("保存草稿成功");
          this.getDeclareDetail();
        } catch (error) {
          this.$toast(error);
        }
      }
    },

    // 参数处理
    paramsInit(type) {
      let declareFileList = [];
      this.form.fileList1.forEach((item) => {
        let obj = {
          ossId: item.ossId,
          fileName: item.fileName,
          fileUrl: item.url,
          fileType: 1,
        };
        declareFileList.push(obj);
      });
      if (type == "submit" && this.form.fileList1.length == 0) {
        this.$toast("请上传猪场照片");
        return null;
      }
      this.form.fileList2.forEach((item) => {
        let obj = {
          ossId: item.ossId,
          fileName: item.fileName,
          fileUrl: item.url,
          fileType: 2,
        };
        declareFileList.push(obj);
      });
      let params = {
        companyId: this.form.companyId,
        declareUserPhone: JSON.parse(localStorage.getItem("user")).userName,
        companyName: this.form.companyName,
        legalPerson: this.form.companyUser,
        idCardNo: this.form.companyUserID,
        phone: this.form.companyUserPhone,
        bank: this.form.companyBank,
        bankNo: this.form.companyBankAccount,
        farmName: this.form.baseName,
        farmLongitude: this.form.longitude,
        farmLatitude: this.form.latitude,
        farmAddress: this.form.baseAddress,
        husbandryCode: this.form.husbandryCode,
        vacCode: this.form.animalCode,
        vacExpiration: this.form.isExpire == "是" ? "Y" : "N",
        sowNumber: this.form.sowFullNum,
        currentSowNumber: this.form.sowCurrentNum,
        digitizeFlag: this.form.digitizeFlag == "是" ? "Y" : "N",
        yearCompanyId: this.form.yearCompanyId, //申报年度企业id
        // fatPigNumber: this.form.fitPigFullNum,
        // currentFatPigNumber: this.form.fitPigCurrentNum,
        // subsidyAmount: this.form.subsidyAmount,
        remark: this.form.remark,
        draftFlag: this.form.draftFlag, // 是否保存草稿
        declareFileList: declareFileList,
      };
      return params;
    },
    uploadFile(blob, name) {
      const formData = new FormData();
      formData.append("file", blob, name);
      noWatermark(formData)
        .then((res) => {
          this.form.fileList1.forEach((item, i) => {
            if (item.file && item.file.name == res.data.fileName)
              this.form.fileList1[i] = res.data;
          });
        })
        .catch((err) => {
          this.$toast("服务上传错误");
          file.status = "failed";
          file.message = "上传失败";
        });
      //   return dataJson;
    },
    // 猪场照片
    async afterReadFirst(file) {
      //   if (!this.form.companyName) {
      //     this.$toast("请填写企业名称");
      //     this.form.fileList1 = [];
      //     return false;
      //   }
      //   // 水印文字
      //   var watermarkText = [
      //     "企业名称：" + this.form.companyName,
      //     "拍照时间：" + parseTime(new Date()),
      //     "拍摄地点：" + this.form.baseAddress,
      //   ];

      //   const img = new Image();
      //   img.src = URL.createObjectURL(file.file);
      //   img.onload = () => {
      //     // 创建Canvas元素
      //     const canvas = document.createElement("canvas");
      //     const ctx = canvas.getContext("2d");
      //     canvas.width = img.width;
      //     canvas.height = img.height;
      //     // 绘制原图片
      //     ctx.drawImage(img, 0, 0);
      //     // 设置水印文字样式
      //     ctx.font = "12px Arial";
      //     ctx.fillStyle = "#FFFFFF"; // 半透明黑色文字
      //     ctx.shadowColor = 'black';
      //     ctx.shadowBlur = 10;
      //     ctx.shadowOffsetX = 5;
      //     ctx.shadowOffsetY = 5;
      //     // 计算水印位置
      //     var textHeight = 12 + 5; // 12是字体大小，5是行间距
      //     var x = 10; // 距离左边界的距离
      //     var y = canvas.height - watermarkText.length * textHeight - 10; // 距离底边界的距离

      //     // 绘制水印文字
      //     for (var i = 0; i < watermarkText.length; i++) {
      //       ctx.fillText(watermarkText[i], x, y + i * textHeight);
      //     }
      //     // file.status = "uploading";
      //     // file.message = "上传中...";
      //     // 将Canvas转换为Blob
      //     canvas.toBlob(
      //       (blob) => {
      //         // 使用新的Blob对象替换原有文件
      //         this.uploadFile(blob, file.file.name);
      //       },
      //       file.file.type,
      //       0.9
      //     ); // 可以调整质量和格式
      //   };
      //   file.status = "";
      //   return;
      if (!this.form.companyName) {
        this.$toast("请填写企业名称");
        this.form.fileList1 = [];
        return false;
      }
      // 单图上传
      let params = {
        file: file.file,
        fileType: 1,
        companyName: "企业名称：" + this.form.companyName,
        date: "拍照时间：" + parseTime(new Date()),
        address: "拍摄地点：" + this.form.baseAddress,
      };
      file.status = "uploading";
      file.message = "上传中...";
      upload(params)
        .then((res) => {
          this.form.fileList1.forEach((item, i) => {
            if (
              item.file &&
              `watermarked_${item.file.name}` == res.data.fileName
            )
              this.form.fileList1[i] = res.data;
          });
          files.status = "";
        })
        .catch((err) => {
          //   this.$toast("服务上传错误");
          file.status = "failed";
          file.message = "上传失败";
        });
    },

    // 猪场全景照片
    afterReadSecond(file) {
      if (!this.form.companyName) {
        this.$toast("请填写企业名称");
        this.form.fileList2 = [];
        return false;
      }
      // 单图上传
      let params = {
        file: file.file,
        fileType: 2,
        companyName: "企业名称：" + this.form.companyName,
        date: "拍照时间：" + parseTime(new Date()),
        address: "拍摄地点：" + this.form.baseAddress,
      };
      file.status = "uploading";
      file.message = "上传中...";
      // 单图上传
      upload(params)
        .then((res) => {
          this.form.fileList2.forEach((item, i) => {
            if (
              item.file &&
              `watermarked_${item.file.name}` == res.data.fileName
            )
              this.form.fileList2[i] = res.data;
          });
          file.status = "";
        })
        .catch((err) => {
          this.$toast("服务上传错误");
          file.status = "failed";
          file.message = "上传失败";
        });
    },

    // 地图初始化

    initMap() {
      if (this.read_only) return;
      AMapLoader.load({
        key: "4c7c0364d134ee37658e8706d5a4ed5c", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          "AMap.ToolBar",
          "AMap.Scale",
          "AMap.Geolocation",
          "AMap.PlaceSearch",
          "AMap.AutoComplete",
          "AMap.Geocoder",
          "AMap.CitySearch",
        ],
        resizeEnable: true,
      })
        .then((AMap) => {
          const that = this;
          that.geocoder = new AMap.Geocoder();
          that.getCurrentLocation(); //获取当前定位
        })
        .catch((e) => {
          console.log(e);
        });
    },

    //获取当前定位
    getCurrentLocation() {
      const that = this;
      that.geolocation = new AMap.Geolocation({
        timeout: 3000, //超过3秒后停止定位，默认：5s
        enableHighAccuracy: true,
        zoomToAccuracy: true, //定位成功后是否自动调整地图视野到定位点
      });
      that.geolocation.getCurrentPosition(function (status, result) {
        //备注：getCurrentPosition方法会调用超时或失败：
        //Get geolocation time out：浏览器定位超时，包括原生的超时，可以适当增加超时属性的设定值以减少这一现象。
        //另外还有个别浏览器（如google Chrome浏览器等）本身的定位接口是黑洞，通过其请求定位完全没有回应，也会超时返回失败。
        //Get geolocation failed：定位失败，Chrome、火狐以及部分套壳浏览器接入的定位服务在国外，有较大限制，失败率高。
        if (status == "complete") {
          that.onComplete(result);
        } else {
          that.onError(result); //失败后可使用getCityInfo获取非精准定位（具体到省市）
        }
      });
    },
    //解析定位结果
    onComplete(data) {
      let that = this;
      let lnglat = [data.position.lng, data.position.lat];
      //经纬度转换为中文地址详情
      that.geocoder.getAddress(lnglat, function (status, result) {
        if (status === "complete" && result.regeocode) {
          that.form.baseAddress = result.regeocode.formattedAddress;
        } else {
          that.$toast("根据经纬度查询地址失败");
        }
      });
    },

    //解析定位错误信息
    onError(data) {
      this.getLngLatLocation();
    },
    //在获取具体定位失败时调用的代码：（非精准定位！！！）
    getLngLatLocation() {
      const that = this;
      that.geolocation.getCityInfo(function (status, result) {
        if (status === "complete") {
          let data = result.position;
          that.form.baseAddress = result.province + result.city;
        } else {
          that.$toast("获取地址失败");
        }
      });
    },
  },
};
</script>
<style>
.form-container .van-field__control,
.form-container .van-field__error-message {
  text-align: right;
}

#container {
  width: 100%;
  height: 300px;
  background: blue;
}

.mark-item .van-field__control {
  text-align: left;
}
</style>
<style lang="scss" scoped>
.form-container {
  padding: 14px;
  padding-top: 76px;
}

.van-field {
  margin-bottom: 14px;
}

.form-container-wrap {
  height: calc(100vh - 46px);
  overflow: auto;
  padding-bottom: 60px;
  .firm_name {
    border-top: 10px solid #f5f5f5;
    /* border-bottom: 10px solid #F5F5F5; */
    position: fixed;
    top: 40px;
    width: 100%;
    background-color: #ffffff;
    z-index: 999;
    height: 70px;
    padding: 0 20px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    span:last-child {
      padding: 0 8px;
      display: inline-block;
      width: 70px;
      text-align: center;
      border-radius: 3px;
      border: 1px solid #165dff;
      color: #165dff;
    }
    .status_wait_review {
      border: 1px solid #f6b91e !important;
      color: #f6b91e !important;
    }
    .status_pass_review {
      border: 1px solid #0dbe5e !important;
      color: #0dbe5e !important;
    }
    .status_refund {
      border: 1px solid #f44302 !important;
      color: #f44302 !important;
    }
  }
}

.upload-container {
  margin: 20px 0;

  .upload-tips-text {
    text-align: left;
    margin-top: 10px;
    font-size: 14px;
  }

  .upload-image-wrap {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .my-upload-style {
    width: 80px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #cccccc;
    background: #fff;
    font-size: 12px;
  }
}

.field-line {
  height: 30px;
  display: flex;
  align-items: center;
  font-size: 12px;
  padding-left: 12px;
  position: relative;
  margin-bottom: 14px;

  &::before {
    content: " ";
    position: absolute;
    left: 0;
    top: 50%;
    height: 14px;
    margin-top: -7px;
    width: 4px;
    background-color: #1989fa;
  }
}

.image-tips {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 12px;
  color: #999;

  .tips-img {
    width: 100%;
    margin-bottom: 10px;
  }
}

.submit-wrap {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  display: flex;
  background: #fff;
  align-items: center;
  padding: 0 20px;

  .btn-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 200px;
    font-size: 12px;

    .icon {
      width: 40px;
      height: 30px;
    }
  }
}
</style>
