<template>
  <div v-if="isMobile" id="app">
    <router-view />
  </div>
  <div v-else id="app" class="app">
    <img src="./assets/vxgj.png" alt="" />
    <p>请在微信客户端打开连接</p>
  </div>
</template>
<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      isMobile: true,
    };
  },
  created() {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
  methods: {},
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f5f5f5;
  min-height: 100vh;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.app {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-top: 160px;
  background: #fff !important;
  flex-direction: column;
  img {
    width: 100px;
    margin-bottom: 20px;
  }
}
</style>
