import request from '@/utils/request'
import qs from 'qs'

// 有水印图片上传
export function upload(data) {
    return request({
        url: '/system/oss/addWatermark',
        method: 'post',
        headers: {
            "Content-Type": 'multipart/form-data; boundary=----'
        },
        data
    })
}
// 文件上传
export function noWatermark(data) {
    return request({
        url: '/system/oss/upload',
        method: 'post',
        headers: {
            "Content-Type": 'multipart/form-data; boundary=----'
        },
        data
    })
}

// 获取申报用户企业列表
export function companyList(userPhone) {
    return request({
        url: `/system/yearCompany/user/${userPhone}`,
        method: 'get'
    })
}



// 获取申报记录
export function declareList(params) {
    return request({
        url: '/system/declare/list',
        method: 'get',
        params
    })
}

// 新增申报记录
export function declareAdd(data) {
    return request({
        url: '/system/declare',
        method: 'post',
        data: data
    })
}

// 修改申报记录
export function declarePut(data) {
    return request({
        url: '/system/declare',
        method: 'put',
        data: data
    })
}

// 申报记录详情
export function declareDetail(declareId) {
    return request({
        url: `/system/declare/${declareId}`,
        method: 'get',
    })
}

// 获取用户登录个人信息
export function getUserInfo() {
    return request({
        url: '/getInfo',
        method: 'get'
    })
}