<template>
    <div class="home">
        <van-pull-refresh v-model="loading" @refresh="onRefresh">
            <div class="main-container">
                <van-nav-bar title="历史记录" left-text="返回" left-arrow @click-left="onClickLeft" />
                <div class="content-list">
                    <div v-if="dataList && dataList.length > 0" class="list-wrap">
                        <div v-for="item in dataList" :key="item.declareId" class="item-init">
                            <div class="name-wrap">
                                <div class="name">{{ item.farmName }}</div>
                                <div class="time">{{ item.createTime }}</div>
                            </div>
                            <div class="type" @click="toEdit(item)">继续编辑</div>
                        </div>
                    </div>
                    <van-empty v-else description="暂无历史记录" />
                </div>
            </div>
        </van-pull-refresh>
    </div>
</template>
  
<script>
import { declareList } from '@/api/api'
export default {
    data() {
        return {
            count: 0,
            loading: false,
            queryForm: {
                pageSize: 10,
                pageNum: 1,
                declareUserPhone: '',
                draftFlag: 'Y', // 草稿标识：Y-草稿，N-非草稿
            },
            dataList: []
        }
    },
    created() {
        this.queryForm.declareUserPhone = JSON.parse(localStorage.getItem('user')).userName;
    },
    mounted() {
        this.getDeclareListFn()
    },
    methods: {

        onRefresh() {
            this.loading = true
            setTimeout(() => {
                this.$toast('刷新成功');
                this.loading = false;
                this.dataList = []
                this.queryForm.pageNum = 1
                this.getDeclareListFn()
            }, 300);
        },

        /**
         * 获取申报列表数据
         */
        async getDeclareListFn() {
            try {
                const { msg, total, rows } = await declareList(this.queryForm)
                this.$toast(msg)
                this.dataList = this.dataList.concat(rows)
            } catch (error) {
                this.$toast(error)
            }
        },

        onClickLeft() {
            this.$router.back()
        },

        toEdit(item) {
            this.$router.push('/add?editType=2&declareId=' + item.declareId)
        }
    }
}
</script>
<style lang="scss" scoped>
.main-container {
    height: 100vh;
}

.content-list {
    height: calc(100% - 46px);
    overflow: auto;

    .list-wrap {
        box-sizing: border-box;
        padding: 14px;
        // height: 100%;

        .item-init {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #fff;
            height: 50px;
            padding: 0 10px;
            border-radius: 10px;
            margin-bottom: 14px;

            .name-wrap {
                .name {
                    font-weight: bold;
                    font-size: 14px;
                }

                .time {
                    font-size: 12px;
                    color: #999;
                }
            }

            .type {
                font-size: 12px;
                border: 1px solid #409EFF;
                color: #409EFF;
                border-radius: 6px;
                width: 70px;
                height: 26px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
</style>