<template>
  <div>
    <van-nav-bar
      title="申报详情"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="form-container-wrap">
      <div class="top-status-wrap">
        <div class="status-name">
          <div class="name">{{ detail.farmName }}申报</div>
          <div class="time">{{ detail.createTime }}</div>
        </div>
        <div class="status">
          <img
            v-if="detail.status == 'pass_review'"
            class="icon"
            src="../../assets/u7.png"
            alt=""
          />
          <img v-else class="icon" src="../../assets/u6.png" alt="" />
        </div>
      </div>
      <van-form>
        <div class="form-container">
          <div class="field-line">企业信息</div>
          <van-field
            label-width="100"
            readonly
            required
            v-model="form.companyName"
            name="企业名称"
            label="企业名称"
          />
          <van-field
            label-width="100"
            readonly
            required
            v-model="form.companyUser"
            name="法定负责人"
            label="法定负责人"
          />
          <van-field
            label-width="100"
            readonly
            required
            v-model="form.companyUserID"
            name="法人身份证号"
            label="法人身份证号"
          />
          <van-field
            label-width="100"
            readonly
            required
            v-model="form.companyUserPhone"
            name="法人手机号码"
            label="法人手机号码"
          />
          <van-field
            label-width="100"
            readonly
            required
            v-model="form.companyBank"
            name="开户行"
            label="开户行"
          />
          <van-field
            label-width="100"
            readonly
            required
            v-model="form.companyBankAccount"
            name="银行账户"
            label="银行账户"
          />
          <div class="field-line">养殖信息</div>
          <van-field
            label-width="120"
            readonly
            required
            v-model="form.baseName"
            name="生猪养殖基地名称"
            label="生猪养殖基地名称"
          />
          <van-field
            label-width="100"
            readonly
            required
            v-model="form.baseAddress"
            name="基地位置"
            label="基地位置"
          />
          <van-field
            label-width="100"
            readonly
            v-model="form.husbandryCode"
            name="畜禽养殖代码"
            label="畜禽养殖代码"
          />
          <van-field
            label-width="170"
            readonly
            required
            v-model="form.animalCode"
            name="动物防疫条件合格证编码"
            label="动物防疫条件合格证编码"
          />
          <van-field
            label-width="190"
            readonly
            required
            v-model="form.isExpire"
            name="动物防疫条件合格证是否到期"
            label="动物防疫条件合格证是否到期"
          />
          <van-field
            label-width="180"
            readonly
            required
            v-model="form.sowFullNum"
            name="能繁母猪满负荷存栏数(头)"
            label="能繁母猪满负荷存栏数(头)"
          />
          <van-field
            label-width="170"
            readonly
            required
            v-model="form.sowCurrentNum"
            name="能繁母猪当前存栏数(头)"
            label="能繁母猪当前存栏数(头)"
          />
          <!-- <van-field label-width="170" readonly required v-model="form.fitPigFullNum" name="肥猪满负荷存栏数(头)"
                        label="肥猪满负荷存栏数(头)" />
                    <van-field label-width="170" readonly required v-model="form.fitPigCurrentNum" name="肥猪当前存栏数(头)"
                        label="肥猪当前存栏数(头)" />
                    <van-field label-width="170" readonly required v-model="form.subsidyAmount" name="申请补贴用金额(万元)"
                        label="申请补贴用金额(万元)" /> -->
          <van-field
            class="mark-item"
            readonly
            v-model="form.remark"
            rows="3"
            label-align="top"
            autosize
            label="其他补充说明"
            type="textarea"
            maxlength="200"
            placeholder="请输入说明"
            show-word-limit
          />
          <div class="field-line">材料拍摄</div>
          <div class="image-tips">
            <img class="tips-img" src="../../assets/sl1.png" alt="" />
            <span>示例图片：</span>
            <span>尽量光线足的条件下，拍摄到母猪范围。</span>
          </div>
          <div class="upload-container">
            <div class="upload-image-wrap">
              <van-uploader
                readonly
                :deletable="false"
                v-model="form.fileList1"
                multiple
                :max-count="form.fileList1.length"
              >
                <template #default>
                  <div class="my-upload-style">
                    <van-icon name="plus" size="30" color="#ccc" />
                    <span>拍照上传</span>
                  </div>
                </template>
              </van-uploader>
            </div>
            <div class="upload-tips-text">猪场照片</div>
          </div>
          <div class="image-tips">
            <img class="tips-img" src="../../assets/sl2.png" alt="" />
            <span>示例图片：</span>
            <span>猪场全景图，需要看见猪场全貌。</span>
          </div>
          <div class="upload-container">
            <div class="upload-image-wrap">
              <van-uploader
                readonly
                :deletable="false"
                v-model="form.fileList2"
                multiple
                :max-count="form.fileList1.length"
              >
                <template #default>
                  <div class="my-upload-style">
                    <van-icon name="plus" size="30" color="#ccc" />
                    <span>拍照上传</span>
                  </div>
                </template>
              </van-uploader>
            </div>
            <div class="upload-tips-text">猪场全景图</div>
          </div>
        </div>
      </van-form>
    </div>
  </div>
</template>
<script>
import { declareDetail } from "@/api/api";
export default {
  data() {
    return {
      detail: "",
      form: {
        companyName: "", // 企业名称
        companyUser: "", // 法定负责人
        companyUserID: "", // 法人身份证号
        companyUserPhone: "", // 法人手机号码
        companyBank: "", // 开户行
        companyBankAccount: "", // 银行账户
        baseName: "", // 生猪养殖基地名称
        baseAddress: "", // 基地位置
        husbandryCode: "", // 畜禽养殖代码
        animalCode: "", // 动物防疫条件合格证编码
        isExpire: "", // 动物防疫条件合格证是否到期
        sowFullNum: "", // 能繁母猪满负荷存栏数(头)
        sowCurrentNum: "", // 能繁母猪当前存栏数(头)
        // fitPigFullNum: '', // 肥猪满负荷存栏数(头)
        // fitPigCurrentNum: '', // 肥猪当前存栏数(头)
        // subsidyAmount: '', // 申请补贴用金额(万元)
        remark: "", // 其他补充说明
        fileList1: [], // 猪场照片
        fileList2: [], // 猪场全景图片
      },
    };
  },
  created() {
    this.declareId = this.$route.query.declareId;
    this.getDeclareDetail();
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    async getDeclareDetail() {
      const { data } = await declareDetail(this.declareId);
      this.detail = data;
      let imageList1 = [];
      let imageList2 = [];
      if (data.declareFileList && data.declareFileList.length > 0) {
        data.declareFileList.forEach((item) => {
          let obj = {
            fileName: item.fileName,
            ossId: item.ossId,
            url: item.fileUrl,
          };
          if (item.fileType == 1) {
            imageList1.push(obj);
          }
          if (item.fileType == 2) {
            imageList2.push(obj);
          }
        });
      }
      this.form = {
        companyName: data.companyName, // 企业名称
        companyUser: data.legalPerson, // 法定负责人
        companyUserID: data.idCardNo, // 法人身份证号
        companyUserPhone: data.phone, // 法人手机号码
        companyBank: data.bank, // 开户行
        companyBankAccount: data.bankNo, // 银行账户
        baseName: data.farmName, // 生猪养殖基地名称
        baseAddress: data.farmAddress, // 基地位置
        longitude: data.farmLongitude, // 经度
        latitude: data.farmLatitude, // 纬度
        husbandryCode: data.husbandryCode, // 畜禽养殖代码
        animalCode: data.vacCode, // 动物防疫条件合格证编码
        isExpire: data.vacExpiration == "Y" ? "是" : "否", // 动物防疫条件合格证是否到期
        sowFullNum: data.sowNumber, // 能繁母猪满负荷存栏数(头)
        sowCurrentNum: data.currentSowNumber, // 能繁母猪当前存栏数(头)
        // fitPigFullNum: data.fatPigNumber, // 肥猪满负荷存栏数(头)
        // fitPigCurrentNum: data.currentFatPigNumber, // 肥猪当前存栏数(头)
        // subsidyAmount: data.subsidyAmount, // 申请补贴用金额(万元)
        remark: data.remark, // 其他补充说明
        fileList1: imageList1, // 猪场照片
        fileList2: imageList2, // 猪场全景图片
      };
    },
    onSubmit() {},
  },
};
</script>
<style>
.form-container .van-field__control,
.form-container .van-field__error-message {
  text-align: right;
}

.mark-item .van-field__control {
  text-align: left;
}
</style>
<style lang="scss" scoped>
.form-container {
  padding: 14px;
}

.top-status-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 120px;
  background: #fff;
  padding: 0 20px;

  .status-name {
    .name {
      font-weight: bold;
      font-size: 14px;
      margin-bottom: 8px;
    }

    .time {
      font-size: 12px;
      color: #999;
    }
  }

  .status {
    width: 60px;
    height: 60px;

    .icon {
      width: 100%;
      height: 100%;
    }
  }
}

.van-field {
  margin-bottom: 14px;
}

.form-container-wrap {
  height: calc(100vh - 46px);
  overflow: auto;
  padding-bottom: 60px;
}

.upload-container {
  margin: 20px 0;

  .upload-tips-text {
    text-align: left;
    margin-top: 10px;
    font-size: 14px;
  }

  .upload-image-wrap {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .my-upload-style {
    width: 80px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #cccccc;
    background: #fff;
    font-size: 12px;
  }
}

.field-line {
  height: 30px;
  display: flex;
  align-items: center;
  font-size: 12px;
  padding-left: 12px;
  position: relative;
  margin-bottom: 14px;

  &::before {
    content: " ";
    position: absolute;
    left: 0;
    top: 50%;
    height: 14px;
    margin-top: -7px;
    width: 4px;
    background-color: #1989fa;
  }
}

.image-tips {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 12px;
  color: #999;

  .tips-img {
    width: 100%;
    margin-bottom: 10px;
  }
}
</style>
